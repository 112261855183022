// JavaScript Document
/**
 * call simpods API, JQuery required
 * @var string parent.page_url defined by the hosting page, pass the parent's url
 * @var string parent.nonce defined by the hosting page for security check 
 */
var href_arr  = window.location.href.split('/');
// get the asset property asset=ID
var media_arr = href_arr[ href_arr.length - 2 ].split('_');
var query_str = href_arr[ href_arr.length - 1 ] + '&media=' + media_arr[ 1 ] ;
	
//var url_str   = parent.page_url + '/' + query_str + '&nonce=' + parent.nonce ;	

//$.get( url_str );

var asset_arr = href_arr[ href_arr.length - 1 ].split('='); 
function simpods_api_fn( answer_ukn ){

	if( typeof parent.pass_asset_fn == 'function' ){
		if ( typeof answer_ukn === 'object' || typeof answer_ukn === 'number' ) {
			parent.pass_asset_fn( asset_arr [ 1 ], answer_ukn );
		} else if( typeof answer_ukn === 'string' )  {
			var answer_obj = JSON.parse( answer_ukn );

			if( typeof answer_obj === 'object' ){
				parent.pass_asset_fn( asset_arr [ 1 ], answer_obj );
			} else {
				parent.pass_asset_fn( asset_arr [ 1 ], answer_ukn );
			}

		} else {
			parent.pass_asset_fn( asset_arr [ 1 ], 1 );
		}
	}
}
function simpods_api_load_fn(){
	return parent.gameAns_arr[ 'asset_' + asset_arr[1] ];
}
var media_arr 		= href_arr[ href_arr.length - 2 ].split('_'); 
var iframeWid_int 	= window.parent.jQuery('iframe.game-' + media_arr[1] ).width();
var icscale_num		= 1;

jQuery(document).ready( function($) {
	var canvasWid_int	= jQuery('#canvas').width();
	
	icscale_num		= Math.round( iframeWid_int / canvasWid_int * 100 ) / 100;
	
}) 
